import React, { useState, useEffect, Fragment } from "react";
import DrawerComponent from "./DrawerComponent";
import leftchevron from "../../../assets/icons/leftchevron.png";
import downchevron from "../../../assets/icons/downchevron.png";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { isAuthenticated, locationInfo } from "../../../services/auth";
import { getData, postData } from "../../../services//http.service";
import Constants from "../../../services/constant";
import EmptyCart from "./EmptyCart";
import OrderSuccessfull from "./OrderSuccessfull";
import Pickup from "../Popup/Pickup";
import { useSelector, useDispatch } from "react-redux";
import convertToEuro from "../../../utils/commonFunctions";

export default function Cart({ onOpenOrderStatus }) {
    const dispatch = useDispatch();
    const bill = useSelector((state) => state.bill);
    const isCartOpen = useSelector((state) => state.isCartOpen);

    useEffect(() => {
        getCart();
        setIsSuccessfull(false);
        return () => {
            dispatch({ type: "set", bill: {} });
        };
    }, [isCartOpen]);

    const [isSuccessfull, setIsSuccessfull] = useState(false);
    const [isPickupOpen, setPickupOpen] = useState(false);
    const [time, setTime] = useState(null);
    const [timeSlots, setTimeSlots] = useState([]);
    const [selected, setSelected] = useState(time);

    const { locationId } = locationInfo();

    const getCart = async () => {
        const params = {
            location: locationId,
        };
        const res = await getData(Constants.END_POINT.VIEW_CART, params);
        if (res.data) {
            setTime(null);
            dispatch({ type: "set", bill: res });
            getPickupTimeSlots();
        }
    };

    const getPickupTimeSlots = async () => {
        const params = {
            location: locationId,
        };
        const res = await getData(Constants.END_POINT.GET_SLOTS, params);
        if (res.success) {
            setTimeSlots(res.data);
        }
    };

    const updateQty = async (id, type) => {
        if (type === "add") {
            const res = await getData(Constants.END_POINT.INCREASE_PRODUCT_CART + id);
            if (res.success) {
                getCart();
            }
        } else {
            const res = await getData(Constants.END_POINT.DECREASE_PRODUCT_CART + id);
            if (res.success) {
                getCart();
            }
        }
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const renderCartItems = () => {
        return bill?.data?.cartItems?.map((item, index) => (
            <Card className="mb-3 border-0 py-1 px-2 bg-white" key={index}>
                <Card.Body className="p-0">
                    <div className="dish_card mb-4 ">
                        <Row className="align-items-center">
                            <Col xs={7} lg={8}>
                                <div className="h5 d-flex mb-3 ">
                                    <div className="dish_name mb-0">
                                        {item?.product?.name}&nbsp;
                                        {/* <span className="font_kaushan golden_color">Kebap</span> */}
                                    </div>
                                </div>
                                <p className="h6">
                                    {!item.removedIngredients.length && !item.selectedAddOns.length ? <br /> : null}
                                    {/* {item.ingredients_name?.toString()} */}
                                    {item.removedIngredients ? (
                                        item.removedIngredients.map((item, i) => (
                                            <span className="text-danger" key={i}>
                                                {i === 0 ? "-" : ", "}
                                                {item.name}
                                            </span>
                                        ))
                                    ) : (
                                        <span className="text-danger"></span>
                                    )}
                                    {item.selectedAddOns.length ? <br /> : null}
                                    {/* {item.addson_name?.toString()} */}
                                    {item.selectedAddOns &&
                                        item.selectedAddOns.map((item, i) => (
                                            <span className="text-success" key={i}>
                                                {i === 0 ? "+" : ", "}
                                                {item.name}
                                            </span>
                                        ))}
                                </p>
                            </Col>
                            <Col xs={5} lg={4}>
                                <div className="quantity d-flex align-items-center  justify-content-end">
                                    <div className="quantity__minus text-dark p-0 text-center" onClick={() => updateQty(item._id, "subtract")}>
                                        <span>-</span>
                                    </div>

                                    <div className="quantity__input bg-dark border-0 text-light p-0 text-center">{item?.qty}</div>

                                    <div className="quantity__plus text-dark p-0 text-center" onClick={() => updateQty(item._id, "add")}>
                                        <span>+</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end  mt-2 ">
                                    <p className="amount h5 mb-0">{convertToEuro(item.product.price)}</p>
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
            </Card>
        ));
    };

    const placeOrder = async () => {
        const params = {
            location: locationId,
        };
        const payload = {
            time: time,
        };
        const res = await postData(Constants.END_POINT.PLACE_ORDER, payload, params);
        if (res.success) {
            getCart();
            // toast.success(res.message);
            setIsSuccessfull(true);
        } else {
            toast.warn(res.message);
        }
        // if (res.success) {
        //     payAtStore();
        // } else {
        //     getCart();
        //     toast.warn(res.message);
        // }
    };

    return (
        <Fragment>
            <DrawerComponent isOpen={isCartOpen} onClose={() => dispatch({ type: "set", isCartOpen: false })}>
                <div className="d-flex p-3 h5 bg-light m-0">
                    <div className="w-25" onClick={() => dispatch({ type: "set", isCartOpen: false })}>
                        <img src={leftchevron} alt="" className="d-none d-md-block" />
                        <img src={downchevron} alt="" className="d-block d-md-none" />
                    </div>
                    <p className="w-75" style={{ marginLeft: "20%" }}>
                        {/* Your Cart */}
                        Warenkorb
                    </p>
                </div>
                {bill?.data?.cartItems?.length > 0 ? (
                    <div className="cart_click bg-light">
                        <div className="container px-4">
                            <p className="h4 font_bolder mb-3">
                                {/* Items  */}
                                Artikel
                            </p>
                            {bill?.data.cartItems?.length && renderCartItems()}
                            <p className="h4 font_bolder mb-3">
                                {/* Bill Details */}
                                Rechnung
                            </p>
                            <Row>
                                <Col xs={8}>
                                    <p className="h5 text-secondary">
                                        {/* Subtotal */}
                                        Netto
                                    </p>
                                </Col>
                                <Col xs={4}>
                                    <div className="d-flex justify-content-end">
                                        <p className="amount h5 mb-0">{convertToEuro(bill?.data?.cartTotal)}</p>
                                        &nbsp;&nbsp;
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={8}>
                                    <p className="h5 text-secondary">
                                        {/* Taxes */}
                                        MwSt.
                                    </p>
                                </Col>
                                <Col xs={4}>
                                    <div className="d-flex justify-content-end">
                                        <p className="amount h5 mb-0">{convertToEuro(bill?.data?.cartTax)}</p>
                                        &nbsp;&nbsp;
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="mb-3">
                                <Col xs={8}>
                                    <p className="h5 text-dark">
                                        {/* Total */}
                                        Brutto
                                    </p>
                                </Col>
                                <Col xs={4}>
                                    <div className="d-flex justify-content-end">
                                        <p className="amount h5 mb-0">{convertToEuro(bill?.data?.cartGrandTotal)}</p>
                                        &nbsp;&nbsp;
                                        {/* <p className="text-dark rounded-circle align-items-center">
                      <img src={Add} alt="" className="w-100" />
                    </p> */}
                                    </div>
                                </Col>
                            </Row>
                            {time ? (
                                <Fragment>
                                    <Button variant="primary" className="bg-success w-100 border-0 p-2 mb-2" onClick={() => setPickupOpen(true)}>
                                        {/* Pickup Time */}
                                        Abholzeit wählen
                                        {time}
                                    </Button>
                                    <Button onClick={handleShow} variant="primary" className="bg-dark text-light w-100 border-0 border-dark px-5 mb-4">
                                        In {/* Place Order */}
                                        {localStorage.getItem("location")
                                            ? (() => {
                                                  const { locationName, address } = locationInfo();
                                                  return " " + locationName + " ";
                                              })()
                                            : ""}
                                        abholen
                                    </Button>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Button variant="primary" className="bg-dark w-100 border-0 p-2 mb-2" onClick={() => setPickupOpen(true)}>
                                        {/* Select Pickup Time */}
                                        Abholzeit wählen
                                    </Button>
                                    <Button
                                        onClick={() => alert("select Pickup Time First")}
                                        variant="primary"
                                        className="bg-secondary text-light w-100 border-0 border-dark px-5 mb-4"
                                    >
                                        In {/* Place Order */}{" "}
                                        {localStorage.getItem("location")
                                            ? (() => {
                                                  const { locationName, address } = locationInfo();
                                                  return " " + locationName + " ";
                                              })()
                                            : ""}
                                        abholen
                                    </Button>
                                </Fragment>
                            )}
                        </div>
                    </div>
                ) : isSuccessfull ? (
                    <OrderSuccessfull
                        onClose={() => {
                            dispatch({ type: "set", isCartOpen: false });
                            setTimeout(() => dispatch({ type: "set", isAccountOpen: true }), 500);
                        }}
                    />
                ) : (
                    <EmptyCart onClose={() => dispatch({ type: "set", isCartOpen: false })} />
                )}
                <Modal size="sm" show={show} onHide={handleClose} centered>
                    <Modal.Header className="border-dark">
                        <Modal.Title className="w-100 text-center">Bestellung bestätigen?</Modal.Title>
                    </Modal.Header>
                    {/* <Modal.Body className="text-center">Möchten Sie diese Bestellung wirklich beastatigen?</Modal.Body> */}
                    <Modal.Footer className="justify-content-center border-0">
                        <Button className="bg-dark border-dark w-25" variant="secondary" onClick={handleClose}>
                            Nein
                        </Button>
                        <Button
                            className="bg-dark border-dark w-25"
                            variant="primary"
                            onClick={() => {
                                placeOrder();
                                handleClose();
                            }}
                        >
                            Ja
                        </Button>
                    </Modal.Footer>
                </Modal>
            </DrawerComponent>
            <Pickup
                show={isPickupOpen}
                onHide={() => {
                    setPickupOpen(false);
                    setSelected(null);
                }}
                setTime={setTime}
                time={time}
                timeSlots={timeSlots}
                selected={selected}
                setSelected={setSelected}
            />
        </Fragment>
    );
}
