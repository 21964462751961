import React from "react";
import Empty_cart from "../../../assets/images/empty_cart.png";
import { Button } from "react-bootstrap";
import selectedLanguage from "../../GermanTranslate";

export default function EmptyCart({ onClose }) {
    return (
        <div>
            <div className="empty_cart text-center p-3">
                <div className="container">
                    <img src={Empty_cart} alt="" className="mb-3 w-100" />
                    <div className="h3 mb-3">{selectedLanguage.SORRY}</div>
                    <div className="h5 mb-3 text-secondary">
                        {/* Your cart is empty, try to explore items. */}
                        Dein Warenkorb ist leer.
                    </div>
                    <Button onClick={onClose} variant="primary" className="bg-dark w-100 mb-3 border-0 p-2 mb-2">
                        {/* Order Now */}
                        Jetzt Bestellen
                    </Button>
                </div>
            </div>
        </div>
    );
}
