let df = "at";

let Languages = {
    en: {
        EMAIL: "Email",
        LOGIN_WITH_PHONE: "Login With Phone",
        LOGIN_WITH_EMAIL: "Login With Email",
        PLEASE_ENTER_VALID_PHONE: "Please enter valid phone.",
        PLEASE_ENTER_PHONE: "Enter your Number",
        WRONG_OTP: "Wrong otp entered",
        SEND_OTP: "Send OTP",
        OTP_VERIFICATION: " OTP verification",
        CONFIRM_THE_CODE: "Confirm",
        ENTER_CODE_PHONE_NUMBER: "Please enter the code phone number",
        ENTER_CODE_EMAIL: "Bitte gib den Code ein",
        SENDING_OTP: "Sending OTP",
        MY_ORDERS: "My Orders",
        ORDER_HISTORY: "Order History",
        SELECT_LOCATION: "Select Location",
        EDIT_ACCOUNT: "Edit account",
        USER: "User",
        LOG_OUT: "Log out",
        UPDATE: "Update",
        CANCEL: "Cancel",
        NAME_REQ: "Name is required",
        ENTER_NAME: "Enter Name",
        ORDER_RECEIVED: "Your order has been received",
        CONGRATULATIONS: "Congratulations!",
        VIEW_ORDER_STATUS: "View Order Status",
        SORRY: "Sorry",
        TAGLINE: "What are you looking for?",
    },
    at: {
        EMAIL: "Email",
        PLEASE_ENTER_PHONE: "Telefonnummer eingeben",
        LOGIN_WITH_PHONE: "Mit Telefonnummer anmelden",
        LOGIN_WITH_EMAIL: "Mit Email anmelden",
        PLEASE_ENTER_VALID_PHONE: "Bitte geben Sie eine gültige Telefonnummer ein.",
        WRONG_OTP: "Falsches OTP eingegeben",
        OTP_REQUIRED: "OTP ist erforderlich",
        SEND_OTP: "OTP senden",
        OTP_VERIFICATION: "verifizierung",
        CONFIRM_THE_CODE: " Code bestätigen",
        ENTER_CODE_PHONE_NUMBER: "Bitte gib den Code ein Telefonnummer",
        ENTER_CODE_EMAIL: "Bitte gib den Code ein",
        SENDING_OTP: "Code anfordern...",
        MY_ORDERS: "Meine Bestellungen",
        SELECT_LOCATION_ERROR: "Bitte Standort auswählen",
        ORDER_HISTORY: "Bestellverlauf",
        SELECT_LOCATION: "Standort auswählen",
        EDIT_ACCOUNT: "Konto bearbeiten",
        USER: "Benutzer",
        LOG_OUT: "Ausloggen",
        UPDATE: "Aktualisieren",
        CANCEL: "Stornieren",
        NAME_REQ: "Name ist erforderlich",
        ENTER_NAME: "Name eingeben",
        ORDER_RECEIVED: "Deine Bestellung wurde aufgenommen",
        CONGRATULATIONS: "Herzlichen Glückwunsch!",
        VIEW_ORDER_STATUS: "Bestellstatus ansehen",
        SORRY: "Entschuldigung!",
        TAGLINE: "Wonach gelüstet es dich?",
    },
};

const selectedLanguage = Languages[df];

export default selectedLanguage;
