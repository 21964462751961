export default class Constants {
    static END_POINT = {
        SIGNUP: "/signup",
        PHONE_VARIFY_SIGNUP: "/phoneverifySignup",
        LOGIN: "",
        LOGIN_BY_EMAIL: "/loginbyemail",
        LOGIN_BY_PHONE: "/request-otp",
        PHONE_VERIFY_OTP: "/otp-signin",
        PHONE_VARIFY: "/phoneverify",
        FORGETPASSWORD: "/forgot_password",
        LOCATION: "/location/",
        INSERTLOCATION: "/insertlocation",
        CATEGOTY: "/categorylists?location=1",
        CATEGORY: "/category",
        PRODUCTS: "/product",
        CART: "/cart/",
        COMPLETE_ORDERS: "/order/",
        USER_DETAILS: "/profile",
        VIEW_CART: "/cart/",
        UPDATE_USER: "/update",
        INCREASE_PRODUCT_CART: "/cart/increase/",
        DECREASE_PRODUCT_CART: "/cart/decrease/",
        GET_SLOTS: "/location-slots/",
        PLACE_ORDER: "/place-order/",
        CANCEL_ORDER: "",
        UPDATE_FCM: "/update/fcm",

        PAYMENT_LINK: "/order-payment-link/",
        INVOICE_LINK: "/order-invoice-link/",

        CHECK_EMAIL: "/check-email",
        CHECK_PHONE: "/check-phone",
        REQUEST_OTP_EMAIL: "/request-otp-email",
        LOGIN_OTP_EMAIL: "/email-otp-signin",
    };
}
