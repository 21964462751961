import React, { Suspense, useEffect } from "react";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import AllRoutes from "./AllRoutes";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { socket } from "./socket";
import { getMyId } from "./services/auth";
import { Sendrequest } from "./utils/commonFunctions.js";
import { onMessageListener } from "./firebase/Firebase.js";
import { toast } from "react-toastify";

const Login = React.lazy(() => import("./Component/Login/EmailLogin"));
const Forget = React.lazy(() => import("./Component/Forgetpassword/ForgetPassword"));
const Reset = React.lazy(() => import("./Component/Forgetpassword/Resetpassword"));
const SendOtp = React.lazy(() => import("./Component/Forgetpassword/Sendotp"));
const Signup = React.lazy(() => import("./Component/Signup/Signup"));
const OneTmePass = React.lazy(() => import("./Component/pages/OneTimePassword"));
const Layout = React.lazy(() => import("./Layout/Index.jsx"));

function App() {
    useEffect(() => {
        if (!socket.connected) {
            socket.connect();
        } else {
            socket.emit("join", { userId: getMyId() });
        }
    }, [socket.connected]);

    useEffect(() => {
        socket.on("connect", () => socket.emit("join", { userId: getMyId() }));
        socket.on("join", (e) => console.log("joined==>", e));
        socket.on("disconnect", () => console.log("disconnected"));
        return () => {
            socket.off("join");
            socket.off("connect");
        };
    }, [socket.connected]);

    useEffect(() => {
        Sendrequest();
        const unsubscribe = onMessageListener().then((payload) => {
            toast.success(payload?.notification?.title, {
                position: "top-right",
                autoClose: 1000,
            });
        });
        return () => {
            unsubscribe.catch((err) => console.log("failed: ", err));
        };
    }, []);
    return (
        <div className="App no-scroll-bar">
            <HashRouter>
                <Layout>
                    <Suspense fallback={"loading"}>
                        <Routes>
                            <Route path="/" element={<Navigate replace to="/home" />} />
                            <Route exact path="/login" name="Login" element={<Login />} />
                            <Route exact path="/signup" name="Signup" element={<Signup />} />
                            <Route exact path="/otp" name="OTP" element={<OneTmePass />} />
                            <Route exact path="/sendotp" name="Send OTP" element={<SendOtp />} />
                            <Route exact path="/forget" name="Forget Password" element={<Forget />} />
                            <Route exact path="/resetpassword" name="Reset Password" element={<Reset />} />
                            <Route path="*" name="Home" element={<AllRoutes socket={socket} />} />
                        </Routes>
                    </Suspense>

                    <ToastContainer
                        position="bottom-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Layout>
            </HashRouter>
        </div>
    );
}

export default App;
