// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDkq19HKBwAM3ZVwK3WxZr4Em0GDmvSO7E",
    authDomain: "denis-kebap-1637907406612.firebaseapp.com",
    projectId: "denis-kebap-1637907406612",
    storageBucket: "denis-kebap-1637907406612.appspot.com",
    messagingSenderId: "262046152967",
    appId: "1:262046152967:web:c559395ff121ca21232da2",
    measurementId: "G-LBC1V4ESQ6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
            console.log("Service Worker Registered:", registration);
        })
        .catch((error) => {
            console.error("Service Worker Registration Failed:", error);
        });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

export { messaging };
