import axios from "axios";
import { isAuthenticated } from "./auth";
import { BASE_URL } from "../constants";

export const getData = (url, params) => {
    return axios
        .get(BASE_URL + url.toString(), {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: `Bearer ${isAuthenticated()}`,
            },
            params: params,
        })
        .then((response) => {
            // if (response.status === 401) {
            //   localStorage.removeItem('jwt')
            // }
            return response.data;
        })
        .catch((error) => {
            // if (error.response.status === 401) {
            //   localStorage.removeItem('jwt')
            // }
            return error;
        });
};
export const postData = (url, body, params) => {
    return axios
        .post(BASE_URL + url.toString(), body, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${isAuthenticated()}`,
            },
            params: params,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};

export const putData = (url, body) => {
    return axios
        .put(BASE_URL + url.toString(), body, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${isAuthenticated()}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};
export const patchData = (url, body) => {
    return axios
        .patch(BASE_URL + url.toString(), body, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${isAuthenticated()}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};
export const deleteData = (url, body) => {
    return axios
        .delete(
            BASE_URL + url.toString(),
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${isAuthenticated()}`,
                },
            },
            body
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};
export const uploadFile = (url, body) => {
    return axios
        .post(
            BASE_URL + url.toString()(),
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${isAuthenticated()}`,
                },
            },
            body
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};

export const putFile = (url, body) => {
    return axios
        .patch(
            BASE_URL + url.toString(),
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${isAuthenticated()}`,
                },
            },
            body
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};
