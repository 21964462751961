import Location from "../Component/pages/SelectLocation";
import Home from "../Component/pages/Home/Home.js";

export const PrivateRoutes = [
    {
        path: "/location",
        name: "Location",
        component: Location,
    },
    {
        path: "/home",
        name: "Home",
        component: Home,
    },
];
