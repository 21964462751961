import { messaging } from "../firebase/Firebase";
import { getToken } from "firebase/messaging";

const convertToEuro = (amount) => {
    if (isNaN(amount)) {
        return "";
    }
    const euroAmount = `€ ${parseFloat(amount).toFixed(2).replace(".", ",")}`;

    // const euroAmount = `€ ${amount.toString().replace(".", ",")}`;
    return euroAmount;
};
export const Sendrequest = async () => {
    console.log("Requesting User Permission……");

    try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            const currentToken = await getToken(messaging, {
                vapidKey: "BDNH9DkebiGqKfiIchbXpnoiLTw4mHP57NFQCdZDsJ62ZK5pdt-d3ICuMZAf8RH3fWri7eUKxopt9vINXvhtzwo",
            });

            if (currentToken) {
                return currentToken;
            } else {
                console.log("Failed to generate the registration token.");
            }
        } else {
            console.log("User Permission Denied.");
        }
    } catch (err) {
        console.log("An error occurred when requesting to receive the token.", err);
    }
};
export default convertToEuro;
