/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import "../App.scss";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../assets/brand/logo.png";
import Icon from "../assets/icons/location.png";
// import Cart from "../assets/icons/new_cart.png";
import Cart from "../assets/icons/shopping-cart (4).png";
import User from "../assets/icons/user.png";
import "react-modern-drawer/dist/index.css";
import { isAuthenticated, locationInfo } from "../services/auth";

import { useSelector, useDispatch } from "react-redux";
export default function Header() {
    const dispatch = useDispatch();

    const bill = useSelector((state) => state.bill);

    const [isExpended, setIsExpended] = useState(false);

    return (
        <div className="Header bg-white w-100 position-fixed">
            <Navbar expand="lg" onToggle={() => setIsExpended(!isExpended)} expanded={isExpended} className="p-2">
                <Container fluid className="px-2">
                    <Navbar.Brand className="d-none d-lg-block">
                        <img src={Logo} alt="" />
                    </Navbar.Brand>
                    <div className="d-flex d-lg-none justify-content-between w-100">
                        {/* <Navbar.Brand className="p-0 w-50">
                            <img src={Logo} alt="" />
                        </Navbar.Brand> */}
                        <div className="h6 p-0 my-auto w-75">
                            <Link style={{ textDecoration: "none", color: "#000" }} to="/location">
                                {localStorage.getItem("location")
                                    ? (() => {
                                          const { locationName, address } = locationInfo();
                                          return (
                                              <>
                                                  {locationName} <br /> {address}
                                              </>
                                          );
                                      })()
                                    : ""}
                                {/* {locationName} <br />
                                {address} */}
                            </Link>
                        </div>
                        <div className="d-flex">
                            <div
                                className="d-flex d-lg-none justify-content-center py-1"
                                onClick={() => {
                                    dispatch({ type: "set", isCartOpen: true });
                                }}
                            >
                                <img src={Cart} alt="" className="my-auto" width="25px" />
                                <div
                                    className="rounded d-flex flex-column justify-content-center text-center text-danger h6"
                                    style={{
                                        width: "24px",
                                        position: "relative",
                                        left: "-42%",
                                        top: "-2px",
                                    }}
                                >
                                    {bill?.data?.cartItems?.length || 0}
                                </div>
                            </div>
                            <div
                                className="d-flex d-lg-none"
                                onClick={() => {
                                    dispatch({ type: "set", isAccountOpen: true });
                                }}
                            >
                                &nbsp; <span className="text-dark h6">Meine Bestellungen</span>
                            </div>
                            {/* <Navbar.Toggle aria-controls="navbarScroll" className="my-auto" /> */}
                        </div>
                    </div>
                    {isAuthenticated() ? (
                        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
                            <Nav className=" my-2 my-lg-0 menu_mobile" style={{ maxHeight: "200px" }} navbarScroll>
                                <Nav.Link className="d-none d-lg-block">
                                    <Link style={{ textDecoration: "none" }} to="/location">
                                        <img src={Icon} alt="" />
                                        &nbsp;{" "}
                                        <span className="text-dark h6">
                                            {localStorage.getItem("location")
                                                ? (() => {
                                                      const { locationName, address } = locationInfo();
                                                      return locationName + " " + address;
                                                  })()
                                                : ""}
                                        </span>
                                    </Link>
                                </Nav.Link>
                                <Nav.Link
                                    className="d-none d-lg-flex justify-content-center"
                                    onClick={() => {
                                        dispatch({ type: "set", isCartOpen: true });
                                    }}
                                >
                                    <img src={Cart} alt="" width="24px" />
                                    <div
                                        className="rounded text-center text-danger h6"
                                        style={{
                                            // backgroundColor: "#000",
                                            // color: "#f80",
                                            minWidth: "25px",
                                            // minHeight: "25px",
                                            position: "relative",
                                            left: "-16%",
                                            top: "-2px",
                                        }}
                                    >
                                        {bill?.data?.cartItems?.length || 0}
                                    </div>
                                    <div style={{ position: "relative", left: "-10%", top: "2px" }} className="text-dark h6">
                                        {/* View Cart */}
                                        Warenkorb
                                    </div>
                                </Nav.Link>
                                <Nav.Link
                                    className="d-none d-md-block"
                                    onClick={() => {
                                        dispatch({ type: "set", isAccountOpen: true });
                                    }}
                                >
                                    <img src={User} alt="" />
                                    &nbsp; <span className="text-dark h6">{/* My Account */}Meine Bestellungen</span>
                                </Nav.Link>
                                {/* for mobile ........................*/}
                                <Nav.Link
                                    className="d-none d-md-none justify-content-start"
                                    onClick={() => {
                                        dispatch({ type: "set", isCartOpen: true });
                                    }}
                                >
                                    <img src={Cart} alt="" width="24px" />
                                    <div
                                        className="rounded text-center text-light h6"
                                        style={{
                                            backgroundColor: "#000",
                                            minWidth: "20px",
                                            minHeight: "20px",
                                            position: "relative",
                                            left: "-5%",
                                            top: "1px",
                                        }}
                                    >
                                        {bill?.data?.length || 0}
                                    </div>
                                    <div style={{ position: "relative", left: "-4%", top: "2px" }} className="text-dark h6">
                                        {/* View Cart */}
                                        Warenkorb
                                    </div>
                                </Nav.Link>
                                <Nav.Link
                                    className="d-block d-md-none"
                                    onClick={() => {
                                        setIsExpended(!isExpended);
                                        setTimeout(() => {
                                            dispatch({ type: "set", isAccountOpen: true });
                                        }, 200);
                                    }}
                                >
                                    <img src={User} alt="" />
                                    &nbsp;{" "}
                                    <span className="text-dark h6">
                                        {/* My Account */}
                                        Meine Bestellungen
                                    </span>
                                </Nav.Link>
                                {/* ......................end  */}
                            </Nav>
                        </Navbar.Collapse>
                    ) : null}
                </Container>
            </Navbar>
        </div>
    );
}
