import React from "react";
import Drawer from "react-modern-drawer";

export default function DrawerComponent({ isOpen, onClose, children }) {
  return (
    <React.Fragment>
      {/* For Web  */}
      <Drawer
        open={isOpen}
        onClose={onClose}
        direction="right"
        className="w-25 d-none d-md-block no-scroll-bar"
        style={{ overflow: "scroll" }}
      >
        {children}
      </Drawer>
      {/* for Mobile  */}
      <Drawer
        open={isOpen}
        onClose={onClose}
        direction="bottom"
        className="w-100 d-block d-md-none no-scroll-bar"
        style={{ overflow: "scroll", height: "93%" }}
      >
        {children}
      </Drawer>
    </React.Fragment>
  );
}
