import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Edit_user from "../../../assets/icons/edit_user.png";
import { getData, postData, putData } from "../../../services/http.service";
import Popup from "./Modal";
import { isAuthenticated } from "../../../services/auth";
import Constants from "../../../services/constant";
import selectedLanguage from "../../GermanTranslate";

export default function Editprofile({ show, onHide, onUpdate }) {
    useEffect(() => {
        if (show) {
            setValues({ name: "", email: "", oldPassword: "", newPassword: "" });
            getProfileData();
            setErr("");
            setMsg("");
        }
    }, [show]);

    const getProfileData = async () => {
        let res = await getData(Constants.END_POINT.USER_DETAILS);

        if (res.success) {
            setValues({ ...values, name: res.data.name, mobile: res.data.mobile });
        }
    };

    const [values, setValues] = useState({
        name: "",
        mobile: "",
        oldPassword: "",
        newPassword: "",
    });
    const [err, setErr] = useState("");
    const [msg, setMsg] = useState("");

    const { name, mobile, oldPassword, newPassword } = values;

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
        setErr("");
        setMsg("");
    };

    const updatePassword = async (e) => {
        e.preventDefault();
        if (oldPassword && newPassword) {
            let formdata = new FormData();
            formdata.append("access_token", isAuthenticated());
            formdata.append("oldPassword", oldPassword);
            formdata.append("newPassword", newPassword);
            formdata.append("cNewPassword", newPassword);
            let res = await postData("/change_password", formdata);
            if (res.success) {
                setMsg(res.message);
                setTimeout(() => onHide(), 2000);
            } else {
                setErr(res.message);
            }
        }
    };

    const updateUser = async (e) => {
        e.preventDefault();
        if (name?.trim().length) {
            let payload = {
                name: name,
            };
            let res = await putData(Constants.END_POINT.UPDATE_USER, payload);
            if (res.success) {
                if (!oldPassword || !newPassword) {
                    setMsg(res.message);
                    onUpdate();
                    setTimeout(() => onHide(), 1500);
                } else {
                    onUpdate();
                }
            }
        } else {
            setErr(selectedLanguage.NAME_REQ);
        }
        updatePassword(e);
    };

    return (
        <Popup show={show} onHide={onHide}>
            <Modal.Body className="bg-white p-4">
                <div className="container">
                    <Form onSubmit={updateUser}>
                        <Form.Group className="my-2">
                            <Form.Label className="text-secondary h6 mb-3">
                                {/* Full Name */}
                                Nickname
                            </Form.Label>
                            <div className="d-flex p-2 rounded bg_light">
                                <img src={Edit_user} alt="" className="h-100 my-auto" />
                                <Form.Control
                                    placeholder={selectedLanguage.ENTER_NAME}
                                    className="border-0 bg_light "
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </Form.Group>
                        {/* <Form.Group className="my-2">
                            <Form.Label className="text-secondary h6 mb-3">Telefon</Form.Label>
                            <div className="d-flex p-2 rounded bg_light">
                                <img src={Edit_user} alt="" className="h-100 my-auto" />
                                <Form.Control
                                    placeholder="Enter mobile "
                                    className="border-0 bg_light "
                                    type="text"
                                    name="mobile"
                                    value={mobile}
                                    disabled
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </Form.Group> */}
                        {err ? <div className="text-danger">{err}</div> : null}
                        {msg ? <div className="text-success">{msg}</div> : null}
                        <div className="d-flex justify-content-evenly mt-4">
                            <Button onClick={onHide} variant="primary" className="bg-transparent text-dark  border-dark ">
                                {/* Cancel */}
                                {selectedLanguage.CANCEL}
                            </Button>
                            <Button variant="primary" className="bg-dark " type="submit">
                                {/* Save Changes */}
                                {selectedLanguage.UPDATE}
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-0 m-0 justify-content-evenly bg-white"></Modal.Footer>
        </Popup>
    );
}
