import jwt_decode from "jwt-decode";

export const isAuthenticated = () => {
    if (typeof window == "undefined") {
        return false;
    }
    if (localStorage.getItem("jwt")) {
        return JSON.parse(localStorage.getItem("jwt"));
    } else {
        return false;
    }
};
export const authenticate = (authToken, next) => {
    if (typeof window !== "undefined") {
        localStorage.setItem("jwt", JSON.stringify(authToken));
        next();
    }
};
export const getMyId = () => {
    if (localStorage.getItem("jwt")) {
        let decoded = jwt_decode(JSON.parse(localStorage.getItem("jwt")));
        return decoded.userId;
    }
};
export const locationInfo = () => {
    if (localStorage?.getItem("location")) {
        let location = JSON.parse(localStorage.getItem("location"));
        let locationId = location?._id;
        let locationName = location?.name;
        let address = location?.address;
        return { locationId, locationName, address };
    } else {
        return {};
    }
};

export const logout = (next) => {
    localStorage.clear();
    next();
};
