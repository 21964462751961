import React, { useState, useEffect } from "react";
import { Nav, Card, Accordion, Button, Modal } from "react-bootstrap";
import Sign from "../../../assets/icons/sign.png";
import OrderDetails from "../../../Component/pages/Popup/OrderDetails";

import { getData, putData } from "../../../services//http.service";
import Constants from "../../../services/constant";
import { locationInfo } from "../../../services/auth";
import moment from "moment";
import selectedLanguage from "../../GermanTranslate";
import { toast } from "react-toastify";
import convertToEuro from "../../../utils/commonFunctions";

export default function Orders({ isOpen, socket }) {
    useEffect(() => {
        if (isOpen) {
            getMyOrders();
        }
    }, [isOpen]);

    const [myOrders, setMyOrders] = useState([]);

    const [id, setId] = useState(null);
    const [order, setOrder] = useState("");
    const [orderDetail, setOrderDetail] = useState({});
    const [initialTime, setInitialTime] = useState(null);
    const [currentTime, setCurrentTime] = useState(null);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        socket.on("orderReload", (e) => {
            console.log("orderReload==>", e);
            if (e.success) {
                const { locationId } = locationInfo();
                if (e.data?.location === locationId) {
                    getMyOrders();
                }
            }
        });
        return () => {
            socket.off("orderReload");
        };
    }, [socket]);

    useEffect(() => {
        if (myOrders?.today) {
            setInitialTime(myOrders?.currentTime);
        }
    }, [myOrders?.currentTime]);

    useEffect(() => {
        if (initialTime !== null) {
            setCurrentTime(getTimeStamp(initialTime));
            const interval = setInterval(() => {
                setCurrentTime((prevTime) => prevTime + 1000);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [initialTime]);
    function epoch(date) {
        return Date.parse(date);
    }
    const dateToday = new Date();
    const timestamp = epoch(dateToday);

    const getMyOrders = async () => {
        const { locationId } = locationInfo();
        const params = {
            location: locationId,
            date: timestamp / 1000,
        };
        const res = await getData(Constants.END_POINT.COMPLETE_ORDERS, params);
        if (res.success) {
            setMyOrders(res.data);
        } else {
            setMyOrders([]);
        }
    };
    const getTimeStamp = (t) => {
        return new Date(`1970-01-01T${t}`).getTime();
    };
    const getBetween = (c, p, d) => {
        if (c) {
            p = getTimeStamp(p);
            c = moment(c);
            let s = moment(p).subtract("minute", d);
            p = moment(p);
            return moment(c).isBetween(moment(s), moment(p));
        }
    };

    const getAfter = (c, p) => {
        if (c) {
            p = getTimeStamp(p);
            p = moment(p);
            c = moment(c);
            return moment(c).isAfter(moment(p));
        }
    };

    const getSame = (c, p) => {
        if (c) {
            p = getTimeStamp(p);
            p = moment(p);
            c = moment(c);
            return moment(c).isSame(moment(p));
        }
    };
    const getColor = (order) => {
        if (order?.type === "DINE_IN") {
            return "bg-yellow-100";
        } else if (getSame(currentTime, order?.pickupTime)) {
            return "bg-green-300";
        } else if (getBetween(currentTime, order?.pickupTime, order?.preprationTime)) {
            return "bg-green-200";
        } else if (getBetween(currentTime, order?.pickupTime, 10)) {
            return "bg-green-100";
        } else if (getAfter(currentTime, order?.pickupTime) && order?.status === "IN_QUE") {
            return "bg-red-100";
        } else if (order?.status === "READY") {
            return "bg-green-100";
        } else if (order?.status === "CANCELED") {
            return "bg-pink-100";
        } else {
            return "surface-0";
        }
    };
    const getDifference = (c, p) => {
        if (c && p) {
            p = getTimeStamp(p);
            p = moment(p);
            c = moment(c);
            return p.diff(c, "minutes");
        }
        return null;
    };

    const getSeverity = (status) => {
        switch (status) {
            case "READY":
                return "success";
            case "CANCELED":
                return "danger";
            default:
                return "success";
        }
    };
    const cancelOrder = async (id) => {
        const res = await putData(Constants.END_POINT.COMPLETE_ORDERS + id);
        if (res.success) {
            getMyOrders();
            toast.success(res.message, {
                position: "top-right",
                autoClose: 1000,
            });
        } else {
            toast.error(res.message, {
                position: "top-right",
                autoClose: 1000,
            });
        }
    };
    const [show, setShow] = useState(null);

    const handleClose = () => setShow(null);
    const handleShow = (id) => setShow(id);

    let { orderCancelBefore } = JSON.parse(localStorage?.getItem("location")) || {};

    const getInvoiceLink = async (id) => {
        setLoading(true);
        const res = await getData(Constants.END_POINT.INVOICE_LINK + id);
        if (res.success) {
            window.open(res.data.invoiceUrl);
        } else {
            toast.error(res.message, {
                position: "top-right",
                autoClose: 1000,
            });
        }
        setLoading(false);
    };

    const getPaymentLink = async (id) => {
        setLoading(true);
        const res = await getData(Constants.END_POINT.PAYMENT_LINK + id);
        if (res.success) {
            window.open(res.data.checkoutUrl);
        } else {
            toast.error(res.message, {
                position: "top-right",
                autoClose: 1000,
            });
        }
        setLoading(false);
    };

    const renderOrders = () => {
        const orderItems = myOrders?.orderList?.map((item, i) => {
            const diff = getDifference(currentTime, item.pickupTime);
            return (
                <Card key={i} className="border-0 mb-3">
                    <Card.Body className="p-0">
                        <div className={"  bg-white p-2 rounded shadow"}>
                            <Card.Text className="d-flex justify-content-between align-items-center">
                                <div
                                    onClick={() => {
                                        setId(item._id);
                                        setOrder(item?.pickupTime?.split(":")[1]);
                                        setOrderDetail(item);
                                    }}
                                >
                                    <p className="text-secondary m-0 d-flex align-items-center">
                                        <span className="text-xsmall text-dark border-end pe-3">
                                            Abholzeit <p className="m-0 text-medium fw-semibold">{item?.pickupTime}</p>
                                        </span>
                                        <span className="text-xsmall text-dark ps-3">
                                            Gesamtpreis
                                            <p className="m-0 text-medium fw-semibold">{convertToEuro(item?.cart?.cartGrandTotal)}</p>
                                        </span>
                                    </p>
                                </div>

                                <div className="d-flex flex-wrap justify-content-end">
                                    {item.invoiceId ? (
                                        <Button
                                            className="my-1 me-1"
                                            variant="dark"
                                            onClick={() => getInvoiceLink(item._id)}
                                            style={{ padding: "5px", fontSize: "12px" }}
                                            disabled={loading}
                                        >
                                            {loading ? "Rechnung..." : "Rechnung"}
                                        </Button>
                                    ) : (
                                        <Button
                                            className="px-3 d-none my-1 me-1"
                                            variant="dark"
                                            onClick={() => getPaymentLink(item._id)}
                                            style={{ padding: "5px", fontSize: "12px" }}
                                            disabled={loading}
                                        >
                                            {loading ? "Pay..." : "Pay"}
                                        </Button>
                                    )}

                                    {diff >= orderCancelBefore && item.status !== "CANCELED" && item.status !== "READY" ? (
                                        !item.invoiceId && (
                                            <Button
                                                className="my-1"
                                                variant="danger"
                                                onClick={() => handleShow(item._id)}
                                                style={{ padding: "5px", fontSize: "12px" }}
                                            >
                                                Stornieren
                                            </Button>
                                        )
                                    ) : (
                                        <Button
                                            className="my-1"
                                            variant={
                                                item.status === "IN_QUE"
                                                    ? "secondary"
                                                    : item.status === "READY"
                                                    ? "success"
                                                    : item.status === "COMPLETED"
                                                    ? "success"
                                                    : item.status === "CANCELED"
                                                    ? "danger"
                                                    : ""
                                            }
                                            style={{ padding: "5px", fontSize: "12px" }}
                                        >
                                            {item.status === "IN_QUE"
                                                ? "In Queue"
                                                : item.status === "READY"
                                                ? "Abholbereit"
                                                : item.status === "COMPLETED"
                                                ? " Abgeschlossen"
                                                : item.status === "CANCELED"
                                                ? "Storniert"
                                                : ""}
                                        </Button>
                                    )}
                                </div>
                            </Card.Text>
                        </div>
                    </Card.Body>
                </Card>
            );
        });
        return orderItems.reverse();
    };

    return (
        <React.Fragment>
            <OrderDetails show={id} onHide={() => setId(null)} order={order} orderDetail={orderDetail} />
            <Nav.Item className="mb-5">
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="1" className="border-0">
                        <Accordion.Header className="border-0 bg-white">
                            <img src={Sign} alt="" />
                            &nbsp;&nbsp;
                            {/* Order History */}
                            {selectedLanguage.ORDER_HISTORY}
                        </Accordion.Header>
                        <Accordion.Body className="p-3">{myOrders?.orderList?.length ? renderOrders() : null}</Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Modal size="sm" show={show} onHide={handleClose} centered>
                    <Modal.Header className="border-dark">
                        <Modal.Title className="w-100 text-center">Bestellung stornieren?</Modal.Title>
                    </Modal.Header>
                    {/* <Modal.Body className="text-center">Sind Sie sicher , dass Sie die Bestellung stornieren mochten?</Modal.Body> */}
                    <Modal.Footer className="justify-content-center border-0">
                        <Button className="bg-dark border-dark w-25" variant="secondary" onClick={handleClose}>
                            Nein
                        </Button>
                        <Button
                            className="bg-dark border-dark w-25"
                            variant="primary"
                            onClick={() => {
                                cancelOrder(show);
                                handleClose();
                            }}
                        >
                            Ja
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Nav.Item>
        </React.Fragment>
    );
}
