import React from "react";

import Place_cart from "../../../assets/images/place_cart.png";
import { Button } from "react-bootstrap";
import selectedLanguage from "../../GermanTranslate";

export default function OrderSuccessfull({ onClose }) {
    return (
        <div>
            <div className="place_order text-center p-3">
                <div className="container">
                    <img src={Place_cart} alt="" className="mb-3" />
                    <div className="h3 mb-3">
                        {/* Thank you! */}
                        {selectedLanguage.CONGRATULATIONS}
                    </div>
                    <div className="h5 mb-3 text-secondary">
                        {/* Your order has been received by our team */}
                        {selectedLanguage.ORDER_RECEIVED}
                    </div>
                    <Button
                        onClick={() => {
                            onClose();
                        }}
                        variant="primary"
                        className="bg-dark w-100 border-0 p-2 mb-2"
                    >
                        {/* View Order Status */}
                        {selectedLanguage.VIEW_ORDER_STATUS}
                    </Button>
                </div>
            </div>
        </div>
    );
}
