import React, { useState, useEffect } from "react";
import DrawerComponent from "./DrawerComponent";
import leftchevron from "../../../assets/icons/leftchevron.png";
import downchevron from "../../../assets/icons/downchevron.png";
import { Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../services/auth";
import { getData } from "../../../services//http.service";
import Constants from "../../../services/constant";
import Orders from "./Orders";
import EditProfile from "../Popup/Editprofile";
import { useSelector, useDispatch } from "react-redux";
import selectedLanguage from "../../GermanTranslate";
export default function MyAccount({ socket }) {
    const dispatch = useDispatch();
    const isAccountOpen = useSelector((state) => state.isAccountOpen);

    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const [user, setUser] = useState({});

    useEffect(() => {
        if (isAccountOpen) {
            getProfileData();
        }
    }, [isAccountOpen]);

    const getProfileData = async () => {
        let res = await getData(Constants.END_POINT.USER_DETAILS);
        if (res.success) {
            setUser(res.data);
        }
    };

    return (
        <DrawerComponent isOpen={isAccountOpen} onClose={() => dispatch({ type: "set", isAccountOpen: false })}>
            <EditProfile show={show} onHide={() => setShow(false)} user={user} onUpdate={getProfileData} />
            <div
                style={{
                    backgroundImage: "linear-gradient(225deg, rgb(224, 129, 108) -108%, white 37%)",
                }}
            >
                <div className="d-flex justify-content-between p-3 h5">
                    <div className="" onClick={() => dispatch({ type: "set", isAccountOpen: false })}>
                        <img src={leftchevron} alt="img" className="d-none d-md-block" />
                        <img src={downchevron} alt="img" className="d-block d-md-none" />
                    </div>
                    <div className="text-center">
                        {/* My Account */}
                        {selectedLanguage.MY_ORDERS}
                    </div>
                    <p className=""></p>
                </div>
                <div className="profile_check px-3">
                    <div className="container">
                        <Card className="border-0 mb-3">
                            <Card.Body>
                                <div className="d-flex mb-3 align-items-center justify-content-center">
                                    <p className="h4">{user?.name || selectedLanguage.USER}</p>
                                </div>
                                <Button variant="primary" className="bg-dark rounded-pill w-100 mb-3 border-0 p-2 mb-2" onClick={() => setShow(true)}>
                                    {/* Edit Profile */}
                                    {selectedLanguage.EDIT_ACCOUNT}
                                </Button>
                            </Card.Body>
                        </Card>
                        <Orders isOpen={isAccountOpen} socket={socket} />
                        {/* <OrderStatus isOpen={isAccountOpen} /> */}
                        <br />
                        <br />
                        <Button
                            onClick={() =>
                                logout(() => {
                                    setTimeout(() => navigate("/login"), 150);
                                    dispatch({ type: "set", isAccountOpen: false });
                                })
                            }
                            variant="primary"
                            className="bg-transparent text-dark w-100 rounded-pill border-dark px-5"
                        >
                            {/* Log out */}
                            {selectedLanguage.LOG_OUT}
                        </Button>
                    </div>
                </div>
                <br />
            </div>
        </DrawerComponent>
    );
}
