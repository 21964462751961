import React from "react";
import Header from "./Header";
export default function Index(props) {
    return (
        <div>
            <Header />
            {props.children}
        </div>
    );
}
