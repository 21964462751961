import React, { useEffect, useState } from "react";
import { Nav, Tab, Row, Col, Card } from "react-bootstrap";
import "./Home.scss";
// import homeMobile from "../../../assets/images/home_background.jpg";
import homeDesktop from "../../../assets/images/bg-desktop.png";
import Add from "../../../assets/icons/add.png";
import ItemCustomize from "../Popup/ItemCustomize";
import { getData, putData } from "../../../services/http.service";
import { locationInfo } from "../../../services/auth";
import CartComponent from "../Drawer/Cart.jsx";
import MyAccount from "../Drawer/MyAccount.jsx";
import Constants from "../../../services/constant";
import { useDispatch, useSelector } from "react-redux";
import selectedLanguage from "../../GermanTranslate.js";
import convertToEuro, { Sendrequest } from "../../../utils/commonFunctions.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Home({ socket }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const cart = useSelector((state) => state.cart);
    const isCartOpen = useSelector((state) => state.isCartOpen);

    const { locationId } = locationInfo();
    console.log("locationId==>", locationId);

    useEffect(() => {
        if (!locationId) {
            navigate("/location");
        }
    }, [locationId]);

    useEffect(() => {
        GetCategory();
    }, []);

    useEffect(() => {
        if (!isCartOpen) {
            if (dishlist.length) {
                getCategoryItems(categoryId);
            }
        }
    }, [isCartOpen]);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [show, setShow] = useState(false);
    const [category, setCategory] = useState([]);
    const [dishlist, setDishList] = useState([]);
    const [categoryId, setCategoryId] = useState("");

    // const [isCartOpen, setIsCartOpen] = useState(false);

    const getCart = async () => {
        const params = {
            location: locationId,
        };
        const res = await getData(Constants.END_POINT.VIEW_CART, params);
        if (res.data) {
            dispatch({ type: "set", bill: res });
        }
    };

    const GetCategory = async () => {
        let location = JSON.parse(localStorage.getItem("location"));

        const params = {
            location: location?._id,
        };

        const response = await getData(Constants.END_POINT.CATEGORY, params);
        if (response.success) {
            setCategory(response.data);
            if (response?.data?.length) {
                setCategoryId(response.data[0]?._id);
                getCategoryItems(response.data[0]?._id);
            }
        }
    };
    const getCategoryItems = async (cat_id) => {
        const params = {
            category: cat_id,
        };
        const res = await getData(Constants.END_POINT.PRODUCTS, params);
        if (res.success) {
            setDishList(res.data);
            getCart();
        }
    };

    const renderMenuCategories = () => {
        return category?.map((item, i) => (
            <Nav.Item
                className="col-md-3 col-6"
                onClick={() => {
                    setCategoryId(item?._id);
                    getCategoryItems(item?._id);
                }}
                key={item?._id}
            >
                <Nav.Link className="tab_nav" eventKey={item?._id}>
                    {item.name}
                </Nav.Link>
            </Nav.Item>
        ));
    };

    const openItemPopup = (item) => {
        item.quantity = 1;
        setShow(true);
        setSelectedProduct(item);
    };

    const updateQty = async (product) => {
        if (product.varients === 1) {
            const res = await getData("/cart/decrease-product/" + product._id);
            if (res.success) {
                getCategoryItems(categoryId);
            }
        } else {
            dispatch({ type: "set", isCartOpen: true });
        }
    };

    const [location, setLocation] = useState({});

    const getLocation = async () => {
        const res = await getData(Constants.END_POINT.LOCATION + locationId, {});
        if (res.data) {
            setLocation(res.data);
            localStorage.setItem("location", JSON.stringify(res.data));
        }
    };

    useEffect(() => {
        getLocation();
    }, [locationId]);

    useEffect(() => {
        socket.on("taglineReload", (e) => getLocation());
        return () => {
            socket.off("taglineReload");
        };
    }, []);

    const updateFCMToken = async () => {
        const fcmToken = await Sendrequest();
        const res = await putData(Constants.END_POINT.UPDATE_FCM, { fcmToken });
        if (res.success) {
            console.log(res);
        } else {
            toast.error(res.message, {
                position: "top-right",
                autoClose: 1000,
            });
        }
    };

    useEffect(() => {
        const fetchToken = async () => {
            const currentFcmToken = await Sendrequest();
            updateFCMToken(currentFcmToken);
        };
        fetchToken();
    }, []);

    const renderMenuItem = () => {
        return dishlist.map((item, i) => (
            <Col key={i} sm={6}>
                <div className="dish_card" key={i}>
                    <Card className="p-2 mb-3">
                        <Card.Body>
                            <Row className="align-items-center">
                                <Col xs={7} lg={8}>
                                    <Card.Title className="d-flex mb-3 ">
                                        <div className="dish_name mb-0">{item.name}&nbsp;</div>
                                    </Card.Title>
                                    <Card.Text className="h6">
                                        {item.ingredients &&
                                            item.ingredients.map((item, i) => (
                                                <span key={i}>
                                                    {i === 0 ? "" : ", "}
                                                    {item.name}
                                                </span>
                                            ))}
                                    </Card.Text>
                                </Col>
                                <Col xs={5} lg={4}>
                                    <div className="d-flex justify-content-end justify-content-md-center flex-md-nowrap flex-wrap mt-4">
                                        <div className="d-flex mb-1">
                                            <p className="amount h5">{convertToEuro(item.price)}</p>
                                            &nbsp;&nbsp;
                                        </div>
                                        {item.qty ? (
                                            <div className="quantity d-flex align-items-center">
                                                <div className="quantity__minus text-dark p-0 text-center" onClick={() => updateQty(item)}>
                                                    <span>-</span>
                                                </div>
                                                <div className="quantity__input bg-dark border-0 text-light p-0 text-center">{item.qty || 0}</div>
                                                <div
                                                    className="quantity__plus text-dark p-0 text-center "
                                                    onClick={() => {
                                                        openItemPopup(item);
                                                    }}
                                                >
                                                    <span>+</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <p
                                                className="rounded-circle align-items-center"
                                                onClick={() => {
                                                    openItemPopup(item);
                                                }}
                                            >
                                                <img src={Add} alt="" className="w-100" />
                                            </p>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </Col>
        ));
    };

    return (
        <div>
            <CartComponent />
            <MyAccount socket={socket} />
            <div className="overflow-hidden">
                <img src={homeDesktop} className="LOgo w-100 mt-5 m-md-0" alt="" />
            </div>
            <div className="list_card">
                <div className="container">
                    <div className="mb-3 text-center pt-2">
                        {/* <h1>What are you Craving for?</h1> */}
                        <h1>{location?.tagLine ? location?.tagLine : selectedLanguage.TAGLINE}</h1>
                    </div>
                    {category?.length ? (
                        <Tab.Container id="left-tabs-example" defaultActiveKey={category?.[0]?._id}>
                            <Nav variant="pills" className="flex-row list_tabs text-center mb-4 m-auto bg_light p-2 rounded justify-content-center">
                                {renderMenuCategories()}
                            </Nav>
                            <Tab.Content>
                                <Row> {dishlist && renderMenuItem()}</Row>
                            </Tab.Content>
                        </Tab.Container>
                    ) : null}
                </div>
            </div>

            <ItemCustomize
                show={show}
                onHide={() => setShow(false)}
                selectedProduct={selectedProduct}
                getCategoryItems={getCategoryItems}
                categoryId={categoryId}
            />
        </div>
    );
}
