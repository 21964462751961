import React from "react";
import { Modal } from "react-bootstrap";
import "../Home/Home.scss";
// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  return (
    <div>
      <Modal show={props.show} onHide={props.onHide} className="add_more">
        {props.children}
      </Modal>
    </div>
  );
}
