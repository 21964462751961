import React from "react";
import { Modal } from "react-bootstrap";
import Popup from "./Modal";
import convertToEuro from "../../../utils/commonFunctions";

export default function OrderDetails({ show, onHide, order, orderDetail }) {
    const renderOrders = () => {
        return orderDetail?.cart?.cartItems?.map((item, i) => (
            <div className="bg-white d-flex justify-content-between px-3 py-2 rounded border my-2" key={i}>
                <div>
                    <div className="h5">{item.product.name}</div>
                    <div className="text-secondary">
                        {/* Price  */}
                        Preis : {convertToEuro(item?.product?.price)}
                    </div>
                    {/* <div className="h6">
            Ingredients: <span></span>
          </div> */}
                    <div className="h6 d-flex flex-wrap">
                        {/* Ingredients */}
                        {/* Zutaten:&nbsp; */}
                        {item.removedIngredients &&
                            item.removedIngredients.map((item, i) => (
                                <span className="text-danger" key={i}>
                                    {i === 0 ? "-" : ", "}
                                    {item.name}
                                </span>
                            ))}
                    </div>
                    <div className="h6 d-flex flex-wrap">
                        {/* Customised */}
                        {/* angepasst:&nbsp; */}
                        {item.selectedAddOns &&
                            item.selectedAddOns.map((item, i) => (
                                <span className="text-success" key={i}>
                                    {i === 0 ? "+" : ", "}
                                    {item.name}
                                </span>
                            ))}
                    </div>
                </div>
                <div className="d-flex justify-content-center flex-column">
                    <div
                        style={{
                            height: "25px",
                            width: "25px",
                            backgroundColor: "#000",
                        }}
                        className="rounded-circle text-light text-center align-middle my-auto"
                    >
                        {item?.qty}
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <Popup show={show} onHide={onHide}>
            <Modal.Body className="bg-light">
                <div className="text-center h4 my-3">
                    {/* Order Details */}
                    Bestelldetails #{order && order}
                </div>
                <div className="container">{orderDetail?.cart?.cartItems && renderOrders()}</div>
            </Modal.Body>
            <Modal.Footer className="border-0 m-0 px-4 bg-white">
                <div className="container text-secondary">
                    <div className="d-flex justify-content-between">
                        <div>
                            {/* Subtotal */}
                            Netto
                        </div>
                        <div> {convertToEuro(orderDetail?.cart?.cartTotal)}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div>
                            {/* Taxes */}
                            MwSt.
                        </div>
                        <div> {convertToEuro(orderDetail?.cart?.cartTax)}</div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between h5">
                        <div>
                            {/* Total */}
                            Brutto
                        </div>
                        <div> {convertToEuro(orderDetail?.cart?.cartGrandTotal)}</div>
                    </div>
                </div>
            </Modal.Footer>
        </Popup>
    );
}
