import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Popup from "./Modal";
export default function Pickup({ show, onHide, time, setTime, timeSlots, selected, setSelected }) {
    const addSlot = () => {
        if (selected) {
            onHide();
            setTime(selected);
        }
    };

    const renderSlots = () => {
        return timeSlots.map((item, i) => (
            <div className="d-flex mb-2 justify-content-around" key={i}>
                <Form.Label className={`h5 ${item.isAvailable === false ? "text-danger" : ""}`}>{item.time}</Form.Label>
                <Form.Check
                    style={{ cursor: "pointer" }}
                    disabled={item.isAvailable === false ? true : false}
                    type={"checkbox"}
                    checked={selected === item.time}
                    onChange={() => setSelected(item.time)}
                />
            </div>
        ));
    };

    return (
        <Popup show={show} onHide={onHide}>
            <Modal.Body>
                <div className="container">
                    <div className="h3 text-center">
                        {/* Select your pickup time */}
                        Abholzeit wählen
                    </div>
                    <hr />
                    <Form style={{ maxHeight: "350px", overflowY: "scroll" }}>{timeSlots.length > 0 && renderSlots()}</Form>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-0 m-0 justify-content-evenly">
                <Button onClick={onHide} variant="primary" className="bg-transparent text-dark border-dark px-5">
                    {/* Back */}
                    Zurück
                </Button>
                <Button type="submit" onClick={(e) => addSlot(e)} variant="primary" className="bg-dark px-5">
                    {/* Next */}
                    Weiter
                </Button>
            </Modal.Footer>
        </Popup>
    );
}
