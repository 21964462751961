import React from "react";
import { PrivateRoutes } from "./AllRoutes";
import Layout from "../Layout/Index";
import { Navigate, Route, Routes } from "react-router-dom";
import { isAuthenticated } from "../services/auth";
export default function index({ socket }) {
    const getAuthRoutes = (routes) => {
        return routes.map((prop, key) => {
            return (
                <Route
                    path={prop.path}
                    exact
                    element={
                        isAuthenticated() ? (
                            <Layout>
                                <prop.component socket={socket} />
                            </Layout>
                        ) : (
                            <Navigate
                                to={{
                                    pathname: "/login",
                                    // state: { from: props.location },
                                }}
                            />
                        )
                    }
                    key={key}
                />
            );
        });
    };
    return (
        <div>
            <Routes>{getAuthRoutes(PrivateRoutes)}</Routes>
        </div>
    );
}
