import React, { useState, useEffect } from "react";
import "../Login/Login.scss";
import bgimage from "../../assets/images/Kebap.jpg";
import Map from "../../assets/icons/map.png";
import { Form, Button } from "react-bootstrap";
import Logo_ico from "../../assets/brand/logo_ico.png";
import { getData } from "../../services/http.service";
import { useNavigate } from "react-router-dom";
import Constants from "../../services/constant";
import selectedLanguage from "../GermanTranslate";

export default function SelectLocation() {
    const [location, setLocation] = useState(null);
    const navigate = useNavigate();

    const fetchLocationList = async () => {
        const response = await getData(Constants.END_POINT.LOCATION, {});
        setLocation(response.data);
    };

    const [locationid, setLocationid] = useState(null);
    const [locationErr, setLocationErr] = useState();

    useEffect(() => {
        fetchLocationList();
    }, []);

    const handleLocation = async (e) => {
        e.preventDefault();
        if (locationid) {
            localStorage.setItem("location", JSON.stringify(locationid));
            navigate("/home");
        } else {
            setLocationErr(selectedLanguage.SELECT_LOCATION_ERROR);
        }
    };

    return (
        <div>
            <div className="bg_container position-relative">
                <img src={bgimage} className="img-fluid w-100" alt="" style={{ overflow: "hidden" }} />
                <div className="container login_section m-0 p-0 w-100 position-absolute">
                    <div className="row m-0 justify-content-end ">
                        <div className="col-lg-4 col-md-6 col-sm-8 p-0 m-0 col-12">
                            <div className="login_form rounded-3 bg-white p-5 ">
                                <div className="logo_icon mb-2 w-50 mx-auto rounded-circle ">
                                    <img src={Logo_ico} alt="" className="w-100" />
                                </div>
                                <div className="row m-0 p-0 mb-3 align-items-center justify-content-center">
                                    <div className="col-md-12 col-12 text-center">
                                        <p className="h5 text-dark text-center font_bolder">
                                            {/* Select Location */}
                                            {selectedLanguage.SELECT_LOCATION}
                                        </p>
                                    </div>
                                </div>
                                <Form onSubmit={(e) => handleLocation(e)}>
                                    {location &&
                                        location.map((item, index) => (
                                            <div
                                                onClick={() => {
                                                    setLocationid(item);
                                                    setLocationErr("");
                                                }}
                                                className={`justify-content-between ${
                                                    locationid?._id === item._id
                                                        ? "d-flex location mb-3 align-items-center p-2 bg-light rounded border border-dark"
                                                        : "d-flex location mb-3 align-items-center p-2"
                                                }`}
                                                key={index}
                                            >
                                                <div className="address">
                                                    <p className="h5 font_bolder">{item.name}</p>
                                                    <p className="text-secondary">{item.address}</p>
                                                </div>
                                                <div className="location_icon">
                                                    <img src={Map} alt="" className=" bg_light rounded-circle p-3" />
                                                </div>
                                            </div>
                                        ))}
                                    <Form.Group className="mb-2">
                                        <Button className="bg-dark border-0 w-100 p-3" type="submit">
                                            {/* Next */}
                                            Weiter
                                        </Button>
                                        {locationErr && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    textAlign: "center",
                                                    marginTop: "15px",
                                                }}
                                            >
                                                {locationErr}
                                            </div>
                                        )}
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
