/* eslint-disable no-array-constructor */
import React, { useEffect, useState } from "react";
import { Nav, Button, Tab, Row, Col, Card, Modal, Form } from "react-bootstrap";
import { postData } from "../../../services/http.service";
import Popup from "./Modal";
import { locationInfo } from "../../../services/auth";
import Constants from "../../../services/constant";
import convertToEuro from "../../../utils/commonFunctions";

export default function ItemCustomize({ selectedProduct, show, onHide, getCategoryItems, categoryId }) {
    useEffect(() => {
        setSelectedAddons([]);
        setselectedIngredients([]);
        setRemovedIngredients([]);
    }, [onHide]);

    useEffect(() => {
        let ing = selectedProduct?.ingredients?.map((item) => item?._id);
        setselectedIngredients(ing);
    }, [selectedProduct]);

    const [selectedIngredients, setselectedIngredients] = useState([]);
    const [removedIngredients, setRemovedIngredients] = useState([]);
    const [selectedAddons, setSelectedAddons] = useState([]);
    const [loading, setLoading] = useState(false);

    //APIs..........

    const AddItemInCart = async (e) => {
        setLoading(true);
        e.preventDefault();
        const { locationId } = locationInfo();

        let payload = {
            location: locationId,
            product: selectedProduct?._id,
            removedIngredients: removedIngredients,
            selectedAddOns: selectedAddons,
            qty: 1,
        };

        const res = await postData(Constants.END_POINT.CART, payload);
        if (res.success) {
            getCategoryItems(categoryId);
            onHide();
            // toast.success("Items added to cart successfully.");
            setLoading(false);
        } else {
            setLoading(false);
        }
    };
    const selectIngredient = (id) => {
        const index = selectedIngredients.findIndex((item) => item === id);

        if (index > -1) {
            const updatedSelectedIngredients = [...selectedIngredients];
            updatedSelectedIngredients.splice(index, 1);
            setselectedIngredients(updatedSelectedIngredients);
            const removedIngredient = selectedIngredients[index];
            setRemovedIngredients([...removedIngredients, removedIngredient]);
        } else {
            setselectedIngredients([...selectedIngredients, id]);
            const updatedRemovedIngredients = removedIngredients.filter((item) => item !== id);
            setRemovedIngredients(updatedRemovedIngredients);
        }
    };

    const selectedAddon = (id) => {
        let index = selectedAddons.findIndex((item) => item === id);
        if (index > -1) {
            selectedAddons.splice(index, 1);
            setSelectedAddons([...selectedAddons]);
        } else {
            selectedAddons.push(id);
            setSelectedAddons([...selectedAddons]);
        }
    };

    const getFinalTotal = () => {
        let total = parseFloat(selectedProduct?.price);
        selectedProduct?.addOns?.map(({ _id, price }, i) => {
            if (selectedAddons.includes(_id) && price) {
                total += parseFloat(price);
            }
        });
        selectedProduct?.ingredients?.map(({ _id, price }, i) => {
            if (removedIngredients?.includes(_id) && price) {
                total -= parseFloat(price);
            }
        });
        return total.toFixed(2);
    };

    const renderIntegredients = () => {
        return selectedProduct?.ingredients?.map((item, i) => (
            <React.Fragment key={i}>
                <Col xs={6}>
                    <Card.Title className=" mb-3 ">{item.name}</Card.Title>
                </Col>
                <Col xs={3}>
                    <div className="d-flex">
                        <p className="amount h5 mb-0">{convertToEuro(item.price)}</p>
                        &nbsp;&nbsp;
                    </div>
                </Col>
                <Col xs={3}>
                    <input
                        style={{ cursor: "pointer" }}
                        type="checkbox"
                        checked={selectedIngredients?.includes(item._id)}
                        onChange={() => selectIngredient(item._id)}
                    />
                </Col>
            </React.Fragment>
        ));
    };
    const renderAddons = () => {
        return selectedProduct?.addOns?.map((item, i) => (
            <React.Fragment key={i}>
                <Col xs={6}>
                    <Card.Title className=""> {item.name}</Card.Title>
                </Col>
                <Col xs={3}>
                    <div className="d-flex">
                        <p className="amount h5 mb-0">{convertToEuro(item.price)}</p>
                        &nbsp;&nbsp;
                    </div>
                </Col>
                <Col xs={3}>
                    <input type="checkbox" style={{ cursor: "pointer" }} checked={selectedAddons.includes(item._id)} onChange={() => selectedAddon(item._id)} />
                </Col>
            </React.Fragment>
        ));
    };

    const showTotal = () => {
        return (
            <>
                <Row className="align-items-center text-center mb-3">
                    <Col xs={6}>
                        <Card.Title className=" ">
                            <p className="text-secondary font_bold m-auto d-block">
                                {/* Item total */}
                                Artikel Gesamt
                            </p>
                        </Card.Title>
                    </Col>
                    <Col xs={3}>
                        <div className="d-flex">
                            <p className="amount text-success h5 mb-0">{convertToEuro(selectedProduct && getFinalTotal())}</p>
                            &nbsp;&nbsp;
                        </div>
                    </Col>
                    <Col xs={3}></Col>
                </Row>
            </>
        );
    };
    return (
        <Popup show={show} onHide={onHide}>
            <Form onSubmit={(e) => AddItemInCart(e)}>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example " defaultActiveKey="ingredients">
                        <Nav variant="pills" className="flex-row list_tabs text-center mb-4 w-100 bg_light p-2 rounded justify-content-center">
                            <Nav.Item className="w-50 ">
                                <Nav.Link eventKey="ingredients" className="tab_nav font_bold">
                                    {/* Ingredients */}
                                    Zutaten
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="w-50">
                                <Nav.Link eventKey="addon" className="tab_nav font_bold">
                                    {/* Add Ons */}
                                    Zusätzlich
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Tab.Content className="mb-3">
                            <Tab.Pane eventKey="ingredients">
                                <div className="select_list">
                                    <Row className="align-items-center text-center mb-2">{selectedProduct?.ingredients && renderIntegredients()}</Row>
                                    {showTotal()}
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="addon">
                                <div className="select_list">
                                    <Row className="align-items-center text-center mb-2">{selectedProduct?.addOns && renderAddons()}</Row>
                                    {showTotal()}
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer className="border-0 m-0 justify-content-evenly">
                    <Button variant="primary" className="bg-transparent text-dark border-dark px-5" onClick={onHide}>
                        {/* Cancel */}
                        Abbrechen
                    </Button>
                    <Button variant="primary" className="bg-dark px-5" type="submit">
                        {
                            loading ? "Fertig" : "Fertig"
                            // "Done"
                        }
                    </Button>
                </Modal.Footer>
            </Form>
        </Popup>
    );
}
